<template>
  <div>
    <base-sign-page
      :title-text="roleLabel"
      :note-text="noteText"
      :column-list="columnList"
      :data-list="dataList"
      @countDataChange="countDataChange"
      :count-fun="countFun"
      :show-search="true"
      :loading="loading"
      :title-menus="titleMenus"
      @clickTitleMenu="clickTitleMenu"
      :table-actions-fixed="true"
      @tableAction="tableAction"
      :table-actions-width="100"
      :table-actions="tableActions"
      :need-pagination="true">
    </base-sign-page>
    <FmModal v-model="openDialog" :mask-closable="false" :title="roleLabel" width="800px">
      <div style="display: flex;" v-if="chooseData">
        <FmForm style="align-items: flex-start; width: 270px;" label-width="100px">
          <FmFormItem style="width: 100%;" :label="roleLabel">
            <FmInput required v-verifier @verifier="(msg) => verifier = msg" v-model="chooseData.name" :placeholder="roleLabel"></FmInput>
          </FmFormItem>
        </FmForm>
      </div>
      <div slot="footer">
        <fm-btn v-loadingx="submiting" :disabled="verifier !== ''" @click="formSubmit">{{chooseData && chooseData.id ? '修改' : '新增'}}{{roleLabel}}</fm-btn>
      </div>
    </FmModal>
    <FmModal v-model="openDialogAuth" :mask-closable="false" :title="roleLabel" width="800px">
      <div style="display: flex;" v-if="chooseData">
        <div style="flex: 1;padding-left: 20px; margin-left: 20px;background: #FFF;">
          <div style="min-height: 350px;max-height: 350px; overflow: auto;padding-top: 10px;">
          <fm-tree v-if="!changeing" placeholder="菜单权限" :key="treeKey" multiple v-loadingx="loading" :nodes="nodes" search is-checked ref="tree"></fm-tree>
          </div>
        </div>
      </div>
      <div slot="footer">
        <fm-btn v-loadingx="submiting"  @click="save">保存</fm-btn>
        <fm-btn v-loadingx="submiting"  @click="openDialogAuth = false">取消</fm-btn>
      </div>
    </FmModal>
  </div>
</template>

<script>
import BaseSignPage from '@/components/base/BaseSignPage'

import {
  initRole
} from '@/config/handle'

import {
  tools
} from '@/fmlib'

import {
  roleRequest as request
} from '@/api'

export default {
  components: {
    BaseSignPage
  },
  props: {
    roleType: String
  },
  computed: {
    sysList () {
      return this.$store.getters.allSysList
    },
    roleLabel () {
      return this.roleType === 'position' ? '职务' : '角色'
    },
    roleTypes () {
      return [{key: 'norm', label: '普通'}, {key: 'special', label: '特殊'}]
    },
    authTypes () {
      return [{key: 'worker', label: '职工'}, {key: 'org', label: '机构'}]
    },
    nodes () {
      // const sysMenus = this.$store.getters.menuList.map(v => v.data).filter(v => v.sys === this.chooseSys)
      const sysMenus = this.$store.getters.menuList.map(v => v.data)
      const funIds = this.chooseData.funIds
      const checked = this.checked

      let funFn = (funs) => {
        return funs.map(fun => {
          return {
            title: fun.name,
            data: Object.assign({isFun: true}, fun),
            checked: funIds.includes(fun.id)
          }
        })
      }

      let fn = (menu) => {
        let clientName = '其他'
        if (menu.clientType === 'web') {
          clientName = '电脑'
        } else if (menu.clientType === 'wap') {
          clientName = '手机端'
        } else if (menu.clientType === 'pda') {
          clientName = 'PDA设备'
        } else if (menu.clientType === 'app') {
          clientName = 'APP'
        }
        return {
          title: menu.name + ' - ' + menu.sys + ' - ' + clientName,
          data: Object.assign({isMenu: true}, menu),
          checked: checked.includes(menu.id)
        }
      }

      let result = sysMenus.filter(v => v.pid === 0).map(fn)
      ;(function each (nodes) {
        nodes.forEach(node => {
          if (node.data.funs && node.data.funs.length) {
            node.children = funFn(node.data.funs)
          } else {
            node.children = sysMenus.filter(v => v.pid === node.data.id).map(fn)
            each(node.children)
          }
        })
      })(result)

      return this.nodesKey > 0 ? result : []
     },
    tableActions: {
      get () {
        return [{
          label: '权限',
          key: 'auth'
        },
        {
          label: '修改',
          key: 'edit'
        },
        {
          label: '删除',
          key: 'delete'
        }].filter(({key}) => this.$authFunsProxy[key])
      }
    },
    titleMenus: {
      get () {
        return [{
          key: 'add',
          label: '新增'
        },
        {
          key: 'init',
          label: '初始化'
        }].filter(({key}) => (key != 'init' || this.roleType === 'role') && this.$authFunsProxy[key])
      }
    },
    columnList: {
      get () {
        return [{
          field: 'name',
          sort: true,
          title: this.roleLabel,
          render: (h, rowData) => {
            return h('div', rowData.name === 'admin' ? '超级管理员' : rowData.name)
          }
        }]
      }
    }
  },
  created () {
    if (this.$authFunsProxy.get) {
      this.loadData()
    }
    if (this.$authFunsProxy.auth) {
      this.$store.dispatch('loadMenuList')
    }
  },
  methods: {
    tableAction (data) {
      this.chooseData = Object.assign({}, data.data)
      this.dataAuthorityConfig = (this.chooseData.dataAuthorityConfig || '').split(',')
      if (this.chooseData && this.chooseData.menus && this.chooseData.menus.length > 0) {
        const checked = (function merge (item) {
          return item.map(v => {
            return [v.id].concat((v.children && v.children.length ? merge(v.children) : []))
          }).flat()
        })(this.chooseData.menus)
        this.$set(this.chooseData, 'menuIds', checked)
        this.checked = [...checked]
      } else {
        this.checked = []
      }
      this.treeKey++
      if (data.action === 'edit') {
        this.openDialog = true
      } else if (data.action === 'auth') {
        this.openDialogAuth = true
      } else if (data.action === 'delete') {
        this.delData()
      }
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除' + this.roleLabel + ' ' + this.chooseData.name + ' 吗?'})
      if (result) {
        request.del(this.chooseData.id).then(() => {
          this.$notice.info({
            title: '系统提示',
            desc: this.roleLabel + '已删除'
          })
          this.loadData()
        })
      }
    },
    formSubmit () {
      this.submiting = true
      if (this.chooseData.id) {
        request.update(this.chooseData.id, {
          name: this.chooseData.name
        }).then(() => {
          this.openDialog = false
          this.submiting = false
          this.$notice.success({
            title: '系统提示',
            desc: this.roleLabel + '修改完成'
          })
          this.loadData()
        })
      } else {
        request.add({
          name: this.chooseData.name,
          roleType: this.roleType
        }).then(() => {
          this.openDialog = false
          this.submiting = false
          this.$notice.success({
            title: '系统提示',
            desc: this.roleLabel + '新增完成'
          })
          this.loadData()
        })
      }
    },
    save () {
      const data = [...this.$refs.tree.checkedList.values()]
      let menuIds = data.filter(({data}) => data.data.isMenu).map(({data}) => data.data.id)
      let funIds = data.filter(({data}) => data.data.isFun).map(({data}) => data.data.id)
      this.$set(this.chooseData, 'menuIds', menuIds)
      this.$set(this.chooseData, 'funIds', funIds)
      this.submiting = true
      request.updateAuth(this.chooseData.id, {
        menuIds: this.chooseData.menuIds || [],
        funIds: this.chooseData.funIds || []
      }).then(() => {
        this.openDialogAuth = false
        this.submiting = false
        this.$notice.success({
          title: '系统提示',
          desc: this.roleLabel + '权限修改完成'
        })
        this.loadData()
      })
    },
    async clickSys(key) {
      this.chooseSys = key
      this.changeing = true
      await tools.sleep(200)
      this.changeing = false
    },
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    async clickTitleMenu (parm) {
      this.chooseData = null
      this.treeKey++
      if (parm === 'add') {
        this.chooseData = {name: '', menuIds: [], funIds: []}
        this.checked = []
        this.dataAuthorityConfig = []
        this.openDialog = true
      } else if (parm === 'init') {
        this.loading = true
        await initRole()
        this.loading = false
        this.loadData()
      }
    },
    loadData () {
      this.loading = true
      let parm = {
        roleType: this.roleType
      }
      request.get(parm).then((data) => {
        if (!this.$store.getters.currentUserIsAdmin) {
          data = data.filter(v => v.id !== 1)
        }
        this.$store.dispatch('setRoleList', data)
        data.forEach(v => v.funIds = (v.funs || []).map(v1 => v1.id))
        this.dataList = data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  },
  data () {
    return {
      verifier: '',
      chooseSys: 'ucenter',
      changeing: false,
      dataList: [],
      dataAuthorityConfig: [],
      checked: [],
      noteText: '',
      loading: true,
      submiting: false,
      chooseData: null,
      openDialog: false,
      openDialogAuth: false,
      treeKey: 0,
      nodesKey: 1
    }
  }
}
</script>
